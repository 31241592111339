<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-diff-details-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ diffViewData.display_id }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">
        <!-- Show Previous Diff -->
        <feather-icon
          :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !openedDiffMeta.hasPreviousDiff}"
          @click="$emit('change-opened-requirement', 'previous')"
        />

        <!-- Show Next Diff -->
        <feather-icon
          :icon="$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
          size="17"
          class="ml-75 cursor-pointer"
          :class="{'text-muted pointer-events-none': !openedDiffMeta.hasNextDiff}"
          @click="$emit('change-opened-requirement', 'next')"
        />
      </div>
    </div>

    <!-- Requirement Diff Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >
      <!--{{ diffViewData }}-->

      <!-- Label Row -->
      <b-row>
        <b-col>
          <div class="email-label mb-0">
            <b-badge
              pill
              class="text-capitalize mr-50"
              :variant="`light-${resolveCompareTypeColor(diffViewData.compare_type)}`"
            >
              {{ diffViewData.compare_type }}
            </b-badge>
          </div>
        </b-col>
        <b-col cols="5">
          <div class="email-label mb-0">
            <b-badge
              v-for="(value, index) in [{ ...diffViewData.added, name: 'added' }, { ...diffViewData.changed, name: 'changed' }, { ...diffViewData.removed, name: 'removed' }]"
              :key="index"
              pill
              class="text-capitalize mr-50"
              :variant="`light-${resolveDiffViewPropertyColour(value.name)}`"
            >
              <div v-if="(Object.keys(value).length - 1) > 0">
                {{ Object.keys(value).length - 1 }} {{ value.name }}
              </div>
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <!-- Changed Requirement -->
      <b-row v-if="diffViewData.compare_type === 'changed'" class="mt-2">
        <!-- Original Panel -->
        <b-col>
          <b-card>
            <div
              v-for="(value, propertyName, index) in diffViewData"
              :key="`${index}-${propertyName}`"
              :class="`bg-light-${resolveDiffViewPropertyColour(propertyName)}`"
            >
              <div v-if="!hiddenCategoriesOriginal.includes(propertyName)">
                <!--<span>{{ propertyName }}</span>-->
                <b-row
                  v-for="(value2, propertyName2) in value" :key="value2"
                  class="mt-75"
                  :class="propertyName === 'added' ? 'invisible' : 'visible'"
                >
                  <b-col class="text-capitalize font-weight-bold font-small-3 mr-2 text-nowrap" cols="1">
                    <span class="mr-4">
                      {{ propertyName2 }}
                    </span>
                  </b-col>
                  <b-col class="ml-4">
                    <span class="ml-4">
                      {{ value2.old || value2 || '...' }}
                    </span>
                  </b-col>
                </b-row>
              </div>
            </div>
            <!--<pre>{{ diffViewData }}</pre>-->
          </b-card>
        </b-col>
        <!-- Arrow -->
        <b-col cols="2" class="text-center" align-self="center">
          <feather-icon
            icon="ArrowRightIcon"
            size="72"
            class="ml-auto mr-auto"
          />
        </b-col>
        <!-- Changed Panel -->
        <b-col>
          <b-card>
            <div
              v-for="(value, propertyName, index) in diffViewData"
              :key="`${index}-${propertyName}`"
              :class="`bg-light-${resolveDiffViewPropertyColour(propertyName)}`"
            >
              <div v-if="!hiddenCategoriesChanged.includes(propertyName)">
                <!--<span>{{ propertyName }}</span>-->
                <b-row
                  v-for="(value2, propertyName2) in value" :key="value2"
                  class="mt-75"
                  :class="propertyName === 'removed' ? 'invisible' : 'visible'"
                >
                  <b-col class="text-capitalize font-weight-bold font-small-3 mr-2 text-nowrap" cols="1">
                    <span class="mr-4">
                      {{ propertyName2 }}
                    </span>
                  </b-col>
                  <b-col class="ml-4">
                    <span class="ml-4" :class="propertyName === 'changed' ? 'font-weight-bolder' : ''">
                      {{ value2.new || value2 || '...' }}
                    </span>
                  </b-col>
                </b-row>
              </div>
            </div>
            <!--<pre>{{ diffViewData }}</pre>-->
          </b-card>
        </b-col>
      </b-row>
      <!-- ./Changed Requirement -->

      <!-- All other Requirement compare_type's -->
      <b-row v-else class="mt-2">
        <b-col>
          <b-card no-body>
            <b-card-body class="">
              <div
                v-for="(value, propertyName, index) in diffViewData"
                :key="`${index}-${propertyName}`"
                class="design-group"
              >
                <div v-if="!hiddenCategoriesChanged.includes(propertyName)">
                  <b-row class="mt-75" align-v="center">
                    <b-col
                      class="text-capitalize font-weight-bolder font-small-3 mr-4 text-nowrap"
                      :class="`text-${resolveCompareTypeColor(diffViewData.compare_type)}`"
                      cols="1"
                    >
                      {{ propertyName }}
                    </b-col>
                    <b-col class="ml-4">
                      {{ value || '...' }}
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card-body>
            <b-card-footer class="card-footer py-50">
              <span class="font-small-2">Created by:</span>
              <span class="font-small-3 mx-50">{{ diffViewData.created_by }}</span>
              <span class="font-small-2">on</span>
              <span class="font-small-3 mx-50">{{ diffViewData.created }}</span>
              <span class="text-muted font-small-3 float-right">
                {{ diffViewData.id }}
              </span>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
      <!-- ./All other Requirement compare_type's -->
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useBaselineView from './useBaselineView'

export default {
  name: 'BaselinesCompareView',
  components: {
    BRow,
    BCol,
    BCard,
    VuePerfectScrollbar,
  },
  props: {
    diffViewData: {
      type: Object,
      required: true,
    },
    openedDiffMeta: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const perfectScrollbarSettings = { maxScrollbarLength: 150 }
    const { resolveCompareTypeColor, resolveDiffViewPropertyColour } = useBaselineView()
    const hiddenCategoriesOriginal = [
      'compare_type', 'baseline_id', 'deletable', 'level', 'display_id', 'id',
      'created_by', 'created',
    ]
    const hiddenCategoriesChanged = [
      'compare_type', 'baseline_id', 'deletable', 'level', 'display_id', 'id',
      'created_by', 'created',
    ]
    console.log('Look here ', props.diffViewData)

    const diffViewDataOrder = ref({
      changed: null,
      inCommon: null,
    })
    watch(props.diffViewData, () => {
      props.diffViewData.value = Object.assign(diffViewDataOrder.value, props.diffViewData.value)
    })

    return {
      perfectScrollbarSettings,
      resolveCompareTypeColor,
      resolveDiffViewPropertyColour,
      hiddenCategoriesOriginal,
      hiddenCategoriesChanged,
    }
  },
}
</script>
